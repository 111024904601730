#knowledge-section {
  background-image: linear-gradient(#83ff09, #0cebeb);

  > .hero-head > .title {
    padding-top: 3rem;
    text-align: center;
    font-size: 30px;
    color: #363636;
  }

  > .hero-body {
    padding-top: 6rem;
    padding-bottom: 3rem;
  }

  .column {
    padding: 0.3rem;
  }
}
