@import "../../../node_modules/bulma/bulma.sass";

#who-i-am-section {
  background: linear-gradient(to right, #ece9e6, #ffffff);

  .image {
    max-width: 250px;

    img {
      border-radius: 20px;
      box-shadow: -5px 5px 30px 0px rgba(97, 93, 97, 1);
    }
  }

  p {
    margin-bottom: 1em;
  }

  @media screen and (min-width: 100px) {
    .has-text-justified {
      padding-left: 3vw;
    }
  }
}
