// Grid columns
@media screen and (max-width: 500px) {
  .column.is-6-mobile-m {
    width: 50%!important;
  }
  .column.is-12-mobile-m {
    width: 100%!important;
  }
  .is-hidden-mobile-m {
    display: none!important;
  }
}

@media screen and (max-width: 300px) {
  .column.is-12-mobile-x {
    width: 100%!important;
  }
  .is-hidden-mobile-x {
    display: none!important;
  }
}

//Image
.image.has-image-centered {
  margin: auto;
}

//List
ul,
ol {
  &.list-unstyled {
    list-style-type: none !important;
  }
}
