$headphone-size: 100px;
$pulse-size: ($headphone-size * 2);
$pulse-bottom: ($headphone-size - $pulse-size);
$headphone-padding: (($pulse-size - $headphone-size) / 2);

.loading-container {
  background-color: transparent;
  z-index: 99999999;

  &.full {
    top: 0;
    left: 0;
    position: fixed;
    width: 100vw;
    height: 100vh;
  }

  > div {
    height: 50%;
    width: 100%;
    position: absolute;
    transition: height 1s;
    background-color: black;
  }

  > div:first-of-type {
    top: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    z-index: 1;
  }

  > div:last-of-type {
    animation-direction: reverse;
    bottom: 0;
    height: calc(50% + 1px);
  }

  .pulse, .fa-headphones {
    display: none;
  }

  &.disabling {
    display: block;

    > div {
      height: 0;
    }
  }

  &.disabled {
    display: none !important;

    * {
      display: none !important;
    }
  }

  
  &.enabled {
    display: block;

    & ~ * {
      display: none !important;
    }

    .fa-headphones {
      display: block;
      color: white;
      z-index: 1;
      font-size: $headphone-size;
      padding: $headphone-padding;
      position: absolute;
      bottom: $pulse-bottom;
    }

    .pulse {
      display: block;
      animation: pulsate 1.5s ease-out;
      animation-iteration-count: infinite;
      opacity: 0;
      border: 3px solid #999;
      border-radius: 50%;
      height: $pulse-size;
      width: $pulse-size;
      position: absolute;
      bottom: $pulse-bottom;

      &.two {
        animation-delay: 1s;
      }

      &.three {
        animation-delay: 1.5s;
      }

      @keyframes pulsate {
        0% {
          transform: scale(0.1, 0.1);
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
        100% {
          transform: scale(1.2, 1.2);
          opacity: 0;
        }
      }
    }
  }
}
