#skills-section {
  background-color: #83ff09;

  > .hero-head > .title {
    text-align: center;
    font-size: 30px;
    height: 100px;
    line-height: 100px;
    background-color: #451873;
    color: white;
  }

  > .hero-body {
    padding-top: 6rem;
    padding-bottom: 2rem;

    .column {
      padding-bottom: 3rem;
    }
  }
}
