@import "../../../node_modules/bulma/bulma.sass";

#main-navbar {
  .navbar-item.has-dropdown {
    .icon {
      margin-right: 5px;

      &.translate {
        width: 20px;
      }
    }
  }
}

.anchor {
  top: -$navbar-height;
  display: block;
  position: relative;
  visibility: hidden;
}
