@charset "utf-8";

$timeline-background: #201e1e;
$timeline-color: white;
$timeline-circle-background-color: #f21f5b;
$timeline-circle-color: white;
$timeline-keyword-background-color: #007315;
$timeline-line-color: #4080ff !important;
$important-point-bullet-horizontal-align: -20px;

@mixin important-point-bullet {
  content: "•";
  position: absolute;
  top: 0px;
  font-size: 1.3em;
  font-weight: 900;
  color: $timeline-line-color;
}

@media (min-width: 576px) {
  .timeline-container:not(.fluid) {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .timeline-container:not(.fluid) {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .timeline-container:not(.fluid) {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .timeline-container:not(.fluid) {
    max-width: 1140px;
  }
}

.timeline-outer-container {
  background: $timeline-background;
  color: $timeline-color;

  > h2 {
    font-size: 30px;
    padding: 50px 0;
    text-align: center;
  }
}

.timeline-container {
  width: 100%;
  padding: 15px 15px 100px;
  margin-right: auto;
  margin-left: auto;
  font-size: 16px;

  .fluid {
    max-width: calc(100% - 60px);
  }

  a {
    color: $timeline-line-color;

    :hover {
      text-decoration: underline;
    }
  }

  > section,
  > .timeline {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    align-items: center !important;
  }

  > section > *,
  > .timeline > * {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }

  > .timeline {
    &:nth-of-type(odd) > .corner:first-child > .line {
      left: 50%;
      top: -50%;
    }

    &:nth-of-type(odd) > .corner:last-child > .line {
      left: -50%;
      top: calc(50% - 3px);
    }

    &:nth-of-type(even) > .corner:first-child > .line {
      left: 50%;
      top: calc(50% - 3px);
    }

    &:nth-of-type(even) > .corner:last-child > .line {
      left: -50%;
      top: -50%;
    }

    .line {
      border-color: $timeline-line-color;
    }

    > div {
      padding: 0;
      height: 40px;
    }

    > .middle {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;

      > .line {
        border-top: 3px solid;
        margin: 0;
        top: 17px;
        position: relative;
        box-sizing: content-box;
        height: 0;
        overflow: visible;
      }
    }

    > .corner {
      display: flex;
      overflow: hidden;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;

      > .line {
        border: 3px solid;
        width: 100%;
        position: relative;
        border-radius: 15px;
      }
    }
  }

  > section {
    min-height: 150px;

    &:nth-of-type(even) {
      justify-content: flex-end !important;
    }

    &:first-of-type:not(:last-of-type) > .label::after {
      height: 50%;
      left: 50%;
      top: 50%;
    }

    &:not(:first-of-type):not(:last-of-type) > .label::after {
      height: 100%;
      left: calc(50% - 3px);
    }

    &:last-of-type:not(:first-of-type) > .label::after {
      height: 50%;
      left: 50%;
      top: 0;
    }

    &:nth-of-type(odd):not(:first-of-type):not(:last-of-type) > .label::after {
      left: 50%;
    }

    &:nth-of-type(even):last-of-type > .label::after {
      left: calc(50% - 3px);
    }

    &:nth-of-type(odd) > details > .important-points-container li:before {
      @include important-point-bullet;
      left: $important-point-bullet-horizontal-align;
    }

    &:nth-of-type(even) > details {
      text-align: right !important;

      > .important-points-container li:after {
        @include important-point-bullet;
        right: $important-point-bullet-horizontal-align;
      }
    }

    > .label {
      display: inline-flex;
      align-self: stretch;
      align-items: center;
      justify-content: center;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
      padding: 0;

      &:not(:last-child) {
        margin-bottom: 0;
      }

      &::after {
        content: "";
        position: absolute;
        border-left: 3px solid;
        z-index: 1;
        border-color: $timeline-line-color;
      }

      > .circle {
        font-weight: 900;
        border-radius: 50%;
        background-color: $timeline-circle-background-color;
        color: $timeline-circle-color;
        border-color: $timeline-line-color;
        height: 120px;
        width: 120px;
        z-index: 2;
        display: table;
        border: 3px solid;
        background-repeat: no-repeat;
        background-position: top;
        background-size: 55px;
        background-position-y: 5px;

        > .time-interval {
          display: table-cell;
          text-align: center;
          vertical-align: bottom;
          padding-bottom: 5px;
          line-height: 1.15;

          > .start {
            font-size: 18px;
            display: block;
          }

          > .end {
            font-size: 26px;
            display: block;
          }
        }
      }
    }

    &[type="graduation"] > .label > .circle {
      background-image: url("../../assets/timeline/icon/graduation.png");
      background-size: 70px;
      background-position-y: 0;
    }

    &[type="project"] > .label > .circle {
      background-image: url("../../assets/timeline/icon/project.png");
    }

    &[type="business"] > .label > .circle {
      background-image: url("../../assets/timeline/icon/businessman.png");
    }

    &[type="course"] > .label > .circle {
      background-image: url("../../assets/timeline/icon/diploma.png");
    }

    &[type="monitoring"] > .label > .circle {
      background-image: url("../../assets/timeline/icon/monitoring.png");
    }

    &[type="certification"] > .label > .circle {
      background-image: url("../../assets/timeline/icon/certified.png");
    }

    &[type="award"] > .label > .circle {
      background-image: url("../../assets/timeline/icon/award.png");
      background-position-y: 0;
    }

    > details {
      flex: 0 0 70%;
      max-width: 70%;

      > summary {
        outline: none;
        font-size: 20px;
        font-weight: 900;

        &:hover {
          cursor: pointer;
        }
      }

      > p {
        &:not(:first-of-type) {
          text-align: justify;
        }

        strong {
          color: $timeline-color;
        }
      }

      > .important-points-container > ul {
        list-style: none;
        padding-top: 10px;
        padding-bottom: 5px;
        line-height: 1.5;

        > li {
          padding: 1px 0;
          position: relative;
        }
      }

      > .keyword-container {
        margin-bottom: 3px;
        max-width: 85%;
        display: inline-block;

        > strong {
          background-color: $timeline-keyword-background-color;
          padding: 2px 5px;
          border-radius: 6px;
          font-size: 12px;
          font-weight: 500;
          display: inline-block;
          margin-top: 3px;

          &:not(:last-child) {
            margin-right: 5px;
          }
        }
      }

      > .time-interval > .separator {
        margin: 0 5px;
      }
    }
  }
}

@media only screen and (max-device-width: 800px) {
  .timeline-container {
    font-size: 14px;

    > section {
      > details {
        flex: 0 0 75%;
        max-width: 75%;

        > summary {
          font-size: 18px;
        }

        img {
          display: none;
        }

        > .keyword-container {
          max-width: 100%;
        }
      }

      > .label > .circle {
        display: none;
      }
    }
  }
}
