#back-to-top {
  background: #000;
  border-radius: 50%;
  bottom: 20px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  color: #fff;
  cursor: pointer;
  display: block;
  height: 56px;
  opacity: 1;
  outline: 0;
  position: fixed;
  right: 20px;
  transition: bottom 0.2s, opacity 0.2s;
  user-select: none;
  width: 56px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

#back-to-top.hidden {
  bottom: -56px;
  opacity: 0;
}
