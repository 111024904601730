@charset "utf-8";

@import "helpers/helpers.scss";

html {
	scroll-behavior: smooth;
}

body {
  font-family: "Roboto", sans-serif;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
	background: #451873;
}
