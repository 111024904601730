#contact-section {
  //   background: linear-gradient(to right, #0cebeb, #20e3b2, #29ffc6);
  background-color: #0cebeb;
  $color: #4a4a4a;

  color: $color;

  h2 {
    margin-bottom: 3rem;
    color: $color;
  }

  .hero-body {
    padding-top: 6rem;
  }

  .fa-at {
    font-size: 10rem;
  }

  a {
    display: block;
    margin-top: 20px;
    color: $color;

    &:hover {
      color: #451873;
    }

    @media screen and (max-width: 400px) {
      font-size: 1.5rem !important;
    }
  }
}
