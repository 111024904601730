#main-footer {
  background-color: #201e1e;
  padding-bottom: 2rem;

  .title {
    font-size: 14px;
    color: white;
  }

  .columns {
    @media screen and (max-width: 500px) {
      &.is-centered {
        justify-content: left;
      }
    }

    .column:not(:last-child) {
      min-width: 25%;
    }
  }

  ol {
    margin-left: 0;

    a {
      font-size: 15px;
      color: rgba(255, 255, 255, 0.6);

      &:hover {
        color: white;
      }

      .icon {
        margin-right: 5px;
        font-size: 22px;
      }
    }
  }

  .listen-music {
    font-size: 60px;
    color: white;
    margin: 30px 0;
    opacity: 0.4;
    cursor: pointer;
    transition: opacity 0.5s ease-in-out;

    @keyframes blink {
      from {
        opacity: 1;
      }
      50% {
        opacity: 0.5;
      }
      to {
        opacity: 1;
      }
    }

    &:not(:hover) {
      animation: blink 3s infinite;
    }

    &:hover {
      opacity: 1;
    }
  }

  .link a {
    &:hover {
      .icon.sonarcloud {
        background-image: url(../../assets/footer/icon/sonarcloud-white.png);
      }
    }
    .icon.sonarcloud {
      background-image: url(../../assets/footer/icon/sonarcloud-gray.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position-y: 1.5px;
    }
  }
}
