$color: white;

#home-section {
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;

  .anchor {
    position: absolute;
    top: 0;
  }

  .title {
    font-family: "Bungee Shade", cursive;
    font-size: calc(6.6px + 4vw);
    color: $color;
    word-spacing: 100vw;
  }

  .subtitle {
    font-family: "Major Mono Display", monospace;
    font-size: calc(6px + 1vw);
    color: $color;
  }

  .mute-volume {
    color: $color;
    font-size: calc(6px + 1vw);

    &:hover {
      cursor: pointer;
    }
  }

  .home-body {
    position: absolute;
    width: 100%;

    > .columns {
      margin: 0;
    }
  }

  .button-container {
    display: block;

    > div {
      padding: 2px;
      
      > a {
        min-width: 120px;
        padding: 5px 2px;
        border: 1px solid $color;
        border-radius: 3px;
        color: $color;
        display: inline-block;

        &:hover {
          background: #272727;
          color: #fff;
        }
      }
    }
  }

  #home-video-container {
    width: 100%;
    height: 100%;

    &.ready + .video-foreground {
      background-color: transparent;
      background-image: none;
    }

    > #home-video {
      width: 100%;
      height: 100%;
    }
  }

  .video-foreground {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;

    &.fallback {
      background-image: url(../../assets/home/fallback-background.jpg);
      background-repeat: no-repeat;
      background-position: 0;
    }
  }

  @media screen and (max-width: 500px) {
    .column {
      padding: 0.2rem;
    }

    .subtitle {
      margin-bottom: 0;
    }
  }
}
